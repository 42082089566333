import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

interface TextContentProps {
  heading?: string
  content: HTMLElement
}
const TextContent: FunctionComponent<TextContentProps> = ({
  heading,
  content,
}) => {
  return (
    <MainGrid>
      <Container>
        {heading && <h1>{heading}</h1>}
        {content && content}
      </Container>
    </MainGrid>
  )
}

export default TextContent

const Container = styled.section`
  width: 100%;
  max-width: 994px;
  margin: 0 auto;
  padding: 20px 0;

  @media (min-width: 1024px) {
    padding: 9.5rem 0 10rem;
  }

  & h1 {
    margin-bottom: 38px;
  }
  & h6 {
    font-weight: bold;
    margin-bottom: 12px;
  }

  & p {
    margin-bottom: 18px;
  }

  & a,
  & a:link,
  & a:visited {
    color: ${theme.brand.blue};
    white-space: nowrap;
  }

  & a:hover,
  & a:active {
    text-decoration: underline;
  }

  & .line {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    margin: 27px 0 40px;
  }

  & li {
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    color: ${theme.text.greyLight};

    @media (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
    &:before {
      height: 15px;
      width: 15px;
      min-height: 15px;
      min-width: 15px;
    }
  }
`
