import React from 'react'
import Layout from '../components/Layout'
import Legal from '../components/Legal/TextContent'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Terms and Conditions',
    url: '/terms',
  },
]

const Privacy = () => {
  return (
    <Layout activeLink="/terms" title="R&D Terms" description="R&D Terms">
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <Legal
        heading="Terms and Conditions"
        content={
          <div>
            {/* <h6>Our Data Protection Principles</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <p>
              Et sollicitudin ac orci phasellus egestas tellus rutrum tellus
              pellentesque. Bibendum neque egestas congue quisque egestas diam.
              Vivamus at augue eget arcu dictum varius duis at consectetur.
              Neque gravida in fermentum et. Tellus pellentesque eu tincidunt
              tortor aliquam nulla facilisi cras fermentum. Porta nibh venenatis
              cras sed felis eget velit aliquet. Volutpat ac tincidunt vitae
              semper quis. Fusce ut placerat orci nulla pellentesque. Accumsan
              in nisl nisi scelerisque eu ultrices vitae auctor eu. Odio
              facilisis mauris sit amet.
            </p>
            <div className="line" />
            <h6>Nunc mattis enim ut tellus elementum sagittis vitae</h6>
            <p>
              Habitasse platea dictumst quisque sagittis. Et sollicitudin ac
              orci phasellus egestas tellus rutrum tellus pellentesque. Bibendum
              neque egestas congue quisque egestas diam.
            </p>
            <p>Vivamus at augue eget arcu dictum varius duis at consectetur:</p>
            <p>
              a. Neque gravida in fermentum et.
              <br /> Tellus pellentesque eu tincidunt tortor aliquam nulla
              facilisi cras fermentum. Porta nibh venenatis cras sed felis eget
              velit aliquet.
            </p>
            <p>
              b. Neque gravida in fermentum et. <br />
              Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi
              cras fermentum. Porta nibh venenatis cras sed felis eget velit
              aliquet.
            </p>
            <p>
              c.Neque gravida in fermentum et. <br />
              Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi
              cras fermentum. Porta nibh venenatis cras sed felis eget velit
              aliquet.
            </p>
            <p>
              Neque gravida in fermentum et. Tellus pellentesque eu tincidunt
              tortor aliquam nulla facilisi cras fermentum. Porta nibh venenatis
              cras sed felis eget velit aliquet.
            </p>
            <p>
              d. Neque gravida in fermentum et.
              <br /> Tellus pellentesque eu tincidunt tortor aliquam nulla
              facilisi cras fermentum. Porta nibh venenatis cras sed felis eget
              velit aliquet.
            </p>
            <p>
              e. Neque gravida in fermentum et.
              <br /> Tellus pellentesque eu tincidunt tortor aliquam nulla
              facilisi cras fermentum. Porta nibh venenatis cras sed felis eget
              velit aliquet.
            </p>
            <div className="line" />
            <h6>Tellus pellentesque eu tincidunt tortor aliquam</h6>
            <p>
              Turpis egestas pretium aenean pharetra magna ac placerat
              vestibulum lectus. Massa vitae tortor condimentum lacinia. Egestas
              egestas fringilla phasellus faucibus scelerisque eleifend donec
              pretium vulputate. At in tellus integer feugiat scelerisque varius
              morbi enim. Facilisi cras fermentum odio eu. Quisque egestas diam
              in arcu cursus euismod. Cum sociis natoque penatibus et magnis.
              Proin sed libero enim sed faucibus. Praesent semper feugiat nibh
              sed. Nibh venenatis cras sed felis eget. Tempor id eu nisl nunc
              mi.
            </p> */}
          </div>
        }
      />
    </Layout>
  )
}

export default Privacy
