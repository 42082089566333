import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface IBreadcrumb {
  data: any
  color?: string
}
const Breadcrumb = ({ data }: IBreadcrumb) => {
  return (
    <Container>
      {data &&
        data.map((item, key) => {
          return (
            <React.Fragment key={key}>
              <LinkBreadcrumb
                // href={key !== data.length - 1 ? item.url : null}
                to={
                  key !== data.length - 1
                    ? (process.env.BASE === '/' ? '' : process.env.BASE) +
                      item.url
                    : null
                }
                key={key}
              >
                {item.title}
              </LinkBreadcrumb>
              <span>/</span>
            </React.Fragment>
          )
        })}
    </Container>
  )
}

export default Breadcrumb

const Container = styled.div`
  align-items: center;
  padding: 10px 0px;
  margin: 0 20px;
  position: relative;
  display: none;
  & span {
    margin: 0 8px;
    font-size: 9px;
    line-height: 11px;
    @media (min-width: 1024px) {
      color: ${theme.text.main};
    }

    &:last-of-type {
      display: none;
    }

    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media (min-width: 768px) {
    margin: 0 3%;
  }
  @media (min-width: 1024px) {
    padding: 20px 0 0;
    margin: 0;
    display: flex;
  }
`

const LinkBreadcrumb = styled(props => <Link {...props} />)`
  &,
  &:link,
  &:visited {
    color: ${theme.text.main};

    font-size: 14px;
    line-height: 16px;
    display: inline;

    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 16px;
      color: ${theme.brand.blue};
    }
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:last-of-type {
    color: ${theme.text.main};
    &:hover,
    &:active {
      text-decoration: none;
      cursor: default;
      color: ${theme.text.main};
      @media (min-width: 1024px) {
        color: ${theme.text.main};
      }
    }
  }
`
